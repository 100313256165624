import React from "react"
import BusinessLoan from "../../../components/body/pages/en-ng/business/business-loan"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const BusinessLoanPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/business/loans/"}
      title="Business Loan with no Collateral in Nigeria | Kuda Business"
      description="Discover Kuda's flexible business loan options in Nigeria, including invoice and LPO financing. Get access to working capital without traditional collateral"
    />
    <BusinessLoan />
  </Layout>
)

export default BusinessLoanPage

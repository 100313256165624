import React, { useEffect, Fragment } from "react"
import ImageDarkTextRightWithButton from "./components/imageDarkTextRightWithButton"
import ImageDarkTextLeftWithButton from "./components/imageDarkTextLeftWithButton"
import CTABusiness from "../../general/ctaBusiness"
import FAQList from '../../general/faqsList';
import InnerPageHeader from "./components/innerPageHeader";
import { scrollToElement } from "../../../../utility/utils"
import DarkBelowFoldCards from "../../general/darkBelowFoldCards";
import MoreForYouComponent from "../../general/more-for-you";
import { moreForYou2 } from "./features";

// hero image   
import EntryIllustration from '../../../../../assets/img/business-loan-illustration.svg'
import EntryBlurIllustration from '../../../../../assets/img/business/blur/business-loan-hero-blur.svg'

import OrganizationIcon from "../../../../../assets/img/organization-icon.inline.svg"
import PercentageIcon from "../../../../../assets/img/percentage.inline.svg"
import NoRoadIcon from "../../../../../assets/img/no-road-icon.inline.svg"

import InvoiceFundsImage from "../../../../../assets/img/business/business_loan/Invoices-fund-illustration.svg"
import PurchaseOrderImage from "../../../../../assets/img/business/business_loan/Purchase-order-illustration.svg"
import QuickApprovalImage from "../../../../../assets/img/business/business_loan/Quick-approval-illustration.svg"
import RepayFinanceImage from "../../../../../assets/img/business/business_loan/Repay-finance-illustration.svg"

import { Img } from "react-image";


const entryContent = {
    title: (<span>
        Apply for <span className="color-secondary">Kuda Business Supply Chain</span> Financing to grow your business!
    </span>),
    subtitle: "Get working capital and pay your vendors easily through the Kuda Business Invoice Financing and Purchase Order Financing options.",
    name: "Apply for Business Loan",
    url: "/en-ng/business/business-loan-application-form/",
}

const businessTopFeatures = [
    {
        icon: <OrganizationIcon />,
        text: "Repay without putting any stress on your cash flow."
    },
    {
        icon: <PercentageIcon />,
        text: "Our business loan interest rates are competitive and free of hidden charges"
    },
    {
        icon: <NoRoadIcon />,
        text: "Loan approvals are quick and you won’t need collateral"
    },
]

const InvoiceFunds = {
    title: "Turn invoices into funds with Invoice Financing.",
    subtitle: "Our Invoice Financing option helps you unlock working capital quickly with your unpaid invoices so you can maintain a steady cash flow.",
    url: "/en-ng/business/business-loan-application-form/",
    name: "Apply Now",
    illustration: (
        <Img src={InvoiceFundsImage} className="kuda-business-image" />

    ),
}

const PurchaseOrder = {
    title: "Do big business with Purchase Order Financing.",
    subtitle: "When you confirm large orders, Purchase Order Financing will get you the money to fulfil them without any pressure on your account balance.",
    url: "/en-ng/business/business-loan-application-form/",
    name: "Apply Now",
    illustration: (

        <Img src={PurchaseOrderImage} className="kuda-business-image" />

    ),
}

const QuickApproval = {
    title: "Apply for financing easily and get a quick approval.",
    subtitle: "Go from application to approval easily on the Kuda Business app and get the financing to move your business forward.",
    url: "/en-ng/business/business-loan-application-form/",
    name: "Apply Now",
    illustration: (

        <Img src={QuickApprovalImage} className="kuda-business-image" />

    ),
}

const RepayFinance = {
    title: "Repay financing without stressing your business.",
    subtitle: "Take loans from Kuda Business now and we’ll match the terms of repayment to your cash flow to keep your finances stable.",
    url: "/en-ng/business/business-loan-application-form/",
    name: "Apply Now",
    illustration: (
        <Img src={RepayFinanceImage} className="kuda-business-image" />
    ),
}

const questions = [
    {
        heading: "What kind of loans can I get through Kuda Business Supply Chain Financing?",
        list: (
            <span className="flex flex-column  mt-2">

                <span className="faq-description f-16">You can get two kinds of loans through Kuda Business Supply Chain FInancing:</span>


                <span className="pt-3 career-message-margin-bottom ">

                    <div className="flex flex-column  leading-md-6 f-md-16">
                        <div className="mb-3">1. Invoicing Financing based on your verified invoices.</div>

                        <div className="mb-3">2. Purchase Order Financing based on confirmed purchase orders.</div>

                    </div>
                </span>
            </span>),
        index: 1
    },
    {
        heading: "Does Kuda Business need collateral to give me a loan?",
        list: (
            <div className="flex flex-column  mt-2">

                <span className="faq-description f-16">
                    No, we don’t need collateral to give you a loan. We’ll finance your business based on your verified invoices or purchase orders.
                </span>
            </div>
        ),
        index: 2
    },
    {
        heading: "How long does it take to get a loan from Kuda Business?",
        list: (
            <div className="flex flex-column  mt-2">

                <span className="faq-description f-16">
                    Our loan application and credit analysis processes are efficient, so we usually disburse loans just a few days after approval.
                </span>
            </div>
        ),
        index: 3
    },

    {
        heading: "How do I track my loan application on Kuda Business?",
        list: (
            <div className="flex flex-column  mt-2">

                <span className="faq-description f-16">
                    You can track your loan application on the Kuda Business app. We’ll also send you a push notification and/or an email at every stage of your application
                </span>
            </div>
        ),
        index: 4
    },
    {
        heading: "What are the repayment terms for Kuda Business loans?",
        list: (
            <div className="flex flex-column  mt-2">

                <span className="faq-description f-16">To avoid putting pressure on business finances, Kuda Business loan repayment terms are flexible and usually based on a business’ cash flow. We’ll let you know the exact repayment terms when you apply for a loan.</span>
            </div>),
        index: 5
    },


]

const BusinessLoan = () => {
    useEffect(() => {
        scrollToElement()
        window.addEventListener("scroll", function () {
            scrollToElement()
        })
    }, [])

    return (

        <Fragment>

            <InnerPageHeader
                title={entryContent.title}
                subtitle={entryContent.subtitle}
                buttonName={entryContent.name}
                buttonUrl={entryContent.url}
                entryIllustration={EntryIllustration}
                fallback={<Img src={EntryBlurIllustration} className="hero-illustration" />}
                isExternal={true}
            />
            <DarkBelowFoldCards topFeatures={businessTopFeatures} />
            <ImageDarkTextRightWithButton
                title={InvoiceFunds.title}
                subtitle={InvoiceFunds.subtitle}
                illustration={InvoiceFunds.illustration}
                name={InvoiceFunds.name}
                url={InvoiceFunds.url}
            />
            <ImageDarkTextLeftWithButton
                title={PurchaseOrder.title}
                subtitle={PurchaseOrder.subtitle}
                illustration={PurchaseOrder.illustration}
                name={PurchaseOrder.name}
                url={PurchaseOrder.url}
            />
            <ImageDarkTextRightWithButton
                title={QuickApproval.title}
                subtitle={QuickApproval.subtitle}
                illustration={QuickApproval.illustration}
                name={QuickApproval.name}
                url={QuickApproval.url}
            />
            <ImageDarkTextLeftWithButton
                title={RepayFinance.title}
                subtitle={RepayFinance.subtitle}
                illustration={RepayFinance.illustration}
                name={RepayFinance.name}
                url={RepayFinance.url}

            />
            <FAQList title={"Kuda Business Loans & Financing FAQ"} questions={questions} />
            <MoreForYouComponent moreForYou={moreForYou2} />
            <CTABusiness />
        </Fragment>
    )
}

export default BusinessLoan

